import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"

import AOS from "aos"
import "aos/dist/aos.css"

import "../../static/vendor/bootstrap/css/bootstrap.min.css"
import "../../static/vendor/bootstrap-icons/bootstrap-icons.css"
import "../../static/vendor/boxicons/css/boxicons.min.css"
import "../../static/vendor/glightbox/css/glightbox.min.css"
import "../../static/vendor/swiper/swiper-bundle.min.css"
import "../styles/style.css"

import HeroImg from "../../static/img/herordm.png"
import AboutImg from "../../static/img/about.jpg"
import Logo from "../../static/img/logo.svg"

export default function Home() {
  const [headerScrolled, setHeaderScrolled] = useState(true)
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      once: true,
      mirror: false,
    })
  }, [])

  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <meta content="width=device-width, initial-scale=1.0" name="viewport" />

        <title>RDM Tech Solutions</title>
        <meta content="" name="description" />
        <meta content="" name="keywords" />

        <link
          href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Roboto:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i"
          rel="stylesheet"
        />

        {/* <!-- ======================================================= */}
        {/* * Template Name: Techie - v4.4.0 */}
        {/* * Template URL: https://bootstrapmade.com/techie-free-skin-bootstrap-3/ */}
        {/* * Author: BootstrapMade.com */}
        {/* * License: https://bootstrapmade.com/license/ */}
        {/* ======================================================== --> */}
      </Helmet>
      <header
        id="header"
        className={headerScrolled ? "header-scrolled fixed-top" : "fixed-top"}
      >
        <div className="container-fluid d-flex align-items-center justify-content-between">
          <a href="/" className="logo" style={{ color: "white" }}>
            <img src={Logo} width="10%" className="" alt="" /> RDM Tech
            Solutions
          </a>

          <nav id="navbar" className="navbar">
            <ul>
              <li>
                <Link className="nav-link scrollto active" to="#hero">
                  Home
                </Link>
              </li>
              <li>
                <Link className="nav-link scrollto" to="#about">
                  About
                </Link>
              </li>
              <li>
                <Link className="nav-link scrollto" to="#contact">
                  Contact
                </Link>
              </li>
            </ul>
            <i className="bi bi-list mobile-nav-toggle"></i>
          </nav>
        </div>
      </header>

      <section id="hero" className="d-flex align-items-center">
        <div className="container-fluid" data-aos="fade-up">
          <div className="row justify-content-center">
            <div className="col-xl-5 col-lg-6 pt-3 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center">
              <h1>Quality Placements</h1>
              <h2>
                IT sourcing that emphasis competency based screening
              </h2>
            </div>
            <div
              className="col-xl-4 col-lg-6 order-1 order-lg-2 hero-img"
              data-aos="zoom-in"
              data-aos-delay="150"
            >
              <img src={HeroImg} className="img-fluid animated" alt="" />
            </div>
          </div>
        </div>
      </section>

      <main id="main">
        <section id="about" className="about">
          <div className="container">
            <div className="row">
              <div
                className="col-lg-6 order-1 order-lg-2"
                data-aos="zoom-in"
                data-aos-delay="150"
              >
                <img src={AboutImg} className="img-fluid" alt="" />
              </div>
              <div
                className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content"
                data-aos="fade-right"
              >
                <h3>About Us</h3>
                <p>
                  Our mission is focused on meeting company intent with
                  candidate capability.
                </p>
                <p>
                  We specialize in finding high-quality talent for permanent
                  roles in your business by screening candidates for both
                  technical &amp; communication skillsets. We know that you have
                  a business to operate, and hiring managers do not have time to
                  call every candidate only to find out that they are not
                  technically fit.
                </p>
                <p>
                  We conduct technical assessments early in the process prior to
                  moving a candidate further in the pipeline in order to
                  minimize the time spent for all parties during the hiring
                  process.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section id="counts" className="counts">
          <div className="container">
            <div className="row">
              <img src={Logo} height="60" className="" alt="" />
            </div>
            {/* <div className="row counters">
              <div className="col-lg-3 col-6 text-center">
                <span
                  data-purecounter-start="0"
                  data-purecounter-end="232"
                  data-purecounter-duration="1"
                  className="purecounter"
                ></span>
                <p></p>
              </div>

              <div className="col-lg-3 col-6 text-center">
                <span
                  data-purecounter-start="0"
                  data-purecounter-end="521"
                  data-purecounter-duration="1"
                  className="purecounter"
                ></span>
                <p></p>
              </div>

              <div className="col-lg-3 col-6 text-center">
                <span
                  data-purecounter-start="0"
                  data-purecounter-end="1463"
                  data-purecounter-duration="1"
                  className="purecounter"
                ></span>
                <p></p>
              </div>

              <div className="col-lg-3 col-6 text-center">
                <span
                  data-purecounter-start="0"
                  data-purecounter-end="15"
                  data-purecounter-duration="1"
                  className="purecounter"
                ></span>
                <p></p>
              </div>
            </div> */}
          </div>
        </section>

        <section id="contact" className="contact section-bg">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>Contact</h2>
            </div>

            <div className="row">
              <div className="col">
                <div className="info-box  mb-4">
                  <i className="bx bx-envelope"></i>
                  <h3>Email Us</h3>
                  <p>staffing@rdmtechsolutions.com</p>
                </div>
              </div>
            </div>

            {/* <div className="row">
          <div className="col">
            <form action="forms/contact.php" method="post" role="form" className="php-email-form">
              <div className="row">
                <div className="col-md-6 form-group">
                  <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" required/>
                </div>
                <div className="col-md-6 form-group mt-3 mt-md-0">
                  <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" required/>
                </div>
              </div>
              <div className="form-group mt-3">
                <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" required/>
              </div>
              <div className="form-group mt-3">
                <textarea className="form-control" name="message" rows="5" placeholder="Message" required></textarea>
              </div>
              <div className="my-3">
                <div className="loading">Loading</div>
                <div className="error-message"></div>
                <div className="sent-message">Your message has been sent. Thank you!</div>
              </div>
              <div className="text-center"><button type="submit">Send Message</button></div>
            </form>
          </div>

        </div> */}
          </div>
        </section>
      </main>

      <footer id="footer">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6 footer-contact">
                <h3>RDM Tech Solutions</h3>
                {/* <p>
              A108 Adam Street <br/>
              New York, NY 535022<br/>
              United States <br/><br/>
              <strong>Phone:</strong> +1 5589 55488 55<br/>
              <strong>Email:</strong> info@example.com<br/>
            </p> */}
              </div>

              <div className="col-lg-2 col-md-6 footer-links">
                <h4>Useful Links</h4>
                <ul>
                  <li>
                    <i className="bx bx-chevron-right"></i> <a href="#">Home</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <a href="#about">About us</a>
                  </li>
                </ul>
              </div>

              <div className="col-lg-3 col-md-6 footer-links">
                <h4>Our Services</h4>
                <ul>
                  <li>
                    <i className="bx bx-chevron-right"></i> Talent Acquisition
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i> Software Development
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i> Product Management
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i> Marketing
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i> Graphic Design
                  </li>
                </ul>
              </div>

              {/* <div className="col-lg-4 col-md-6 footer-newsletter">
            <h4>Join Our Newsletter</h4>
            <p>Tamen quem nulla quae legam multos aute sint culpa legam noster magna</p>
            <form action="" method="post">
              <input type="email" name="email"/><input type="submit" value="Subscribe"/>
            </form>
          </div> */}
            </div>
          </div>
        </div>

        <div className="container">
          <div className="copyright-wrap d-md-flex py-4">
            <div className="me-md-auto text-center text-md-start">
              <div className="copyright">
                &copy; Copyright{" "}
                <strong>
                  <span>RDM Tech Solutions</span>
                </strong>
                . All Rights Reserved
              </div>
              <div className="credits">
                Designed by{" "}
                <a href="https://bootstrapmade.com/">BootstrapMade</a>
              </div>
            </div>
            {/* <div className="social-links text-center text-md-right pt-3 pt-md-0">
          <a href="#" className="twitter"><i className="bx bxl-twitter"></i></a>
          <a href="#" className="facebook"><i className="bx bxl-facebook"></i></a>
          <a href="#" className="instagram"><i className="bx bxl-instagram"></i></a>
          <a href="#" className="google-plus"><i className="bx bxl-skype"></i></a>
          <a href="#" className="linkedin"><i className="bx bxl-linkedin"></i></a>
        </div> */}
          </div>
        </div>
      </footer>

      {/* <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"></i></a> */}
      {/* <div id="preloader"></div> */}
      {/* 
  <script src="../../static/vendor/aos/aos.js"></script>
  <script src="../../static/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
  <script src="../../static/vendor/glightbox/js/glightbox.min.js"></script>
  <script src="../../static/vendor/isotope-layout/isotope.pkgd.min.js"></script>
  <script src="../../static/vendor/php-email-form/validate.js"></script>
  <script src="../../static/vendor/purecounter/purecounter.js"></script>
  <script src="../../static/vendor/swiper/swiper-bundle.min.js"></script>

  <script src="../../static/js/main.js"></script> */}
    </>
  )
}
